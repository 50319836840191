import Reveal from "../shared/Reveal";

const people = [

		{
			"TW": 1,
			"LW": 9,
			"TITLE": "DIRTY CASH",
			"ARTIST": "PAWSA & ADVENTURES OF STEVIE V",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/9e/39/09/9e390963-ad37-3d16-91cd-d620ad693685/8718274967463.png/600x600bb.jpg"
		  },
		  {
			"TW": 2,
			"LW": 2,
			"TITLE": "TOLD YOU SO",
			"ARTIST": "MARTIN GARRIX, JEX",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/d0/bf/45/d0bf45af-cde5-0fac-90c5-d4994d0b91bb/24UM1IM22864.rgb.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 3,
			"LW": 11,
			"TITLE": "IT FEELS SO GOOD",
			"ARTIST": "MATT SASSARI & HUGEL",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/fa/07/f6/fa07f690-29a7-5d8b-d9ab-01c9b06a1a0a/cover.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 4,
			"LW": 10,
			"TITLE": "OLVIDARTE",
			"ARTIST": "GORDO",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/1e/63/71/1e63711d-b763-6bea-6e9a-cc59435b87f3/196872729757.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 5,
			"LW": 4,
			"TITLE": "CRY BABY",
			"ARTIST": "CLEAN BANDIT, ANNE-MARIE, DAVID GUETTA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/cb/e7/ac/cbe7acd4-a8c9-1e09-486a-1be76da97746/196872448962.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 6,
			"LW": 17,
			"TITLE": "LOVE IS THE ONLY THING",
			"ARTIST": "LOST FREQUENCIES ",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/3a/5b/67/3a5b674d-e77c-d74a-5337-e163190db3f5/196872553710.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 7,
			"LW": 14,
			"TITLE": "FREED FROM DESIRE 2024",
			"ARTIST": "GALA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/73/ef/b2/73efb2e2-c902-8f6f-6148-0fc537dc0665/196872218275.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 8,
			"LW": 12,
			"TITLE": "BORN TO LOVE YA",
			"ARTIST": "SEAN PAUL, GABRY PONTE, NATTI NATASHA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/72/8f/66/728f66b7-8715-a190-09de-f685d2eabbef/5021732489579.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 9,
			"LW": 20,
			"TITLE": "BODY TALK",
			"ARTIST": "ALOK & CLEMENTINE DOUGLAS",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/bd/f0/d2/bdf0d2bc-1816-a347-09da-d350e50d7846/196872654974.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 10,
			"LW": 7,
			"TITLE": "SUPERNOVA LOVE",
			"ARTIST": "IVE, DAVID GUETTA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/e9/f4/e6/e9f4e659-5321-9c7e-cd60-02067199d4d9/24UM1IM21583.rgb.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 11,
			"LW": 19,
			"TITLE": "UNA VELITA",
			"ARTIST": "TIËSTO",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/37/ba/8c/37ba8c86-57e1-8579-a47f-db7c8bf47374/5021732583642.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 12,
			"LW": 5,
			"TITLE": "WORLD GONE WILD",
			"ARTIST": "CYRIL, SAM MARTIN",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/cb/b8/2f/cbb82f7c-c094-ebb9-d91b-17fd0d1e5753/5021732491848.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 13,
			"LW": 16,
			"TITLE": "NEVER GOING HOME TONIGHT",
			"ARTIST": "DAVID GUETTA & ALESSO",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/b0/78/37/b078373d-9c74-7795-d0c6-50b951514b61/5021732472793.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 14,
			"LW": 3,
			"TITLE": "MOVE",
			"ARTIST": "ADAM PORT, STRYV",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/7b/84/6d/7b846d83-4d1f-36f4-32e5-6289596980c2/dj.kdflvmhp.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 15,
			"LW": 6,
			"TITLE": "LOOKING FOR LOVE",
			"ARTIST": "ALOK & ANITTA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/43/6e/d1/436ed1db-a062-c049-1ded-b2f696b8f779/196872545692.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 16,
			"LW": 8,
			"TITLE": "HOLD ON ME",
			"ARTIST": "KYGO, SANDRO CAVAZZA",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/1a/26/78/1a2678eb-686e-d879-3d1f-782ba76b547a/196872600469.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 17,
			"LW": 15,
			"TITLE": "ESPRESSO",
			"ARTIST": "SABRINA CARPENTER",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/1f/c9/3b/1fc93bbf-42f1-4385-0a3a-34d60f4e0451/artwork.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 18,
			"LW": 1,
			"TITLE": "SOMEDAYS",
			"ARTIST": "SONNY FODERA, JAZZY, D.O.D",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/05/ed/1b/05ed1b55-dd04-fb9c-e7fb-8d8505916ed1/085365288423.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 19,
			"LW": 18,
			"TITLE": "BELIEVE",
			"ARTIST": "R3HAB, MUFASA & HYPEMAN, RANI",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/9c/c7/9b/9cc79bc0-0ba2-b53a-e4b1-77e16a92c90d/24UM1IM09562.rgb.jpg/600x600bb.jpg"
		  },
		  {
			"TW": 20,
			"LW": 13,
			"TITLE": "FOREVER YOUNG",
			"ARTIST": "DAVID GUETTA, ALPHAVILLE & AVA MAX",
			"image": "https://is1-ssl.mzstatic.com/image/thumb/Music221/v4/4b/bc/81/4bbc8125-172f-89ae-e2d4-b0c9ed702337/5021732519450.jpg/600x600bb.jpg"
		  }

  ];

export default function Chart() {
  return (
    <div className=" w-full mt-3 relative">
      <div className="flex flex-col items-start h-full  shadow-md rounded-md overflow-hidden w-full">
        <div className="bg-[#2b0b3d] border-b-2 px-4 py-3 w-full">
          <div className="flex justify-between items-center uppercase ">
            <div className="flex space-x-3">
              <p className="text-sm font-semibold text-white">This Week</p>
              <p className="text-sm font-semibold text-white">Name</p>
            </div>
            <div>
              <p className="text-sm font-semibold text-white">Last Week</p>
            </div>
          </div>
        </div>

        <ul className="divide-y divide-gray-700 overflow-y-auto h-80 sm:h-full max-h-[518px] w-full overflow-x-hidden scroll-design">
          {people.map((person, index) => (
            <Reveal key={index}
              hidden={{
                opacity: 0,
                x: index % 2 === 0 ? -75 : 75,
              }}
              visible={{ opacity: 1, x: 0 }}>
              <li
                key={index}
                className={`flex justify-between p-3 hover:bg-purple-200 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
              >
                <div className="flex items-center gap-3">
                  <p className="text-sm p-2 m-1 font-medium text-white bg-theme-third rounded-full h-[40px] w-[40px] flex items-center justify-center">
                    {String(person.TW).padStart(2, "0")}
                  </p>
                  <img
                    className="h-10 w-10 m-1 rounded-full"
                    src={person.image}
                    alt=""
                  />
                  <div className="flex flex-col items-start">
                    <p className="text-sm font-medium text-gray-800">
                      {person.TITLE}
                    </p>
                    <p className="text-sm text-gray-600">{person.ARTIST}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm font-medium text-gray-500 ml-2">
                    {person.LW}
                  </p>
                </div>
              </li>
            </Reveal>
          ))}
        </ul>
      </div>
    </div >

  );
}
