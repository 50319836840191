import React from "react";
import websiteLogo from "../../assets/logos/logo.svg";
import { useNavigate } from "react-router-dom";

function Logo({ className }) {
  const navigate = useNavigate();
  return (
    <img
      onClick={() => navigate("/")}
      className={`w-20 md:w-28 shrink-0 cursor-pointer ${className}`}
      src={websiteLogo}
      alt="logo"
    />
  );
}

export default Logo;
