import React, { useState } from "react";
import axios from "axios";

import TextBox from "./input/TextBox";
import Select from "./input/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    type: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formURL = "https://getform.io/f/7844afc9-36c2-428e-8bde-641a79c185b4";

    axios
      .post(formURL, formData)
      .then((response) => {
        toast("Form submitted successfully!!", {
          hideProgressBar: false,
          autoClose: 7000,
          type: "Success",
        });
      })
      .catch((error) => {
        toast("Error submitting form!", {
          hideProgressBar: false,
          autoClose: 7000,
          type: "error",
        });
        console.error("Error submitting form:", error);
        // Handle any errors that occurred during the form submission
      });
  };
  return (
    <section className="md:px-24 py-14 pb-[100px] px-8 flex flex-col items-center gap-12 lg:gap-0 bg-white">
      <div className="mx-auto max-w-2xl sm:text-center mb-16">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Contact Us
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Have questions? Contact us anytime, and we'll be happy to assist you!
        </p>
      </div>
      <div className="w-full lg:w-[60%] lg:max-w-[600px] text-[#1E1E1E] p-4 md:p-4 bg-gray-300/30 backdrop-blur-lg rounded-lg">
        <form className="flex flex-col text-[#1E1E1E]" onSubmit={handleSubmit}>
          <h1
            className="text-[#1E1E1E] text-5xl font-black uppercase mb-12"
            style={{ fontSize: "25px", textAlign: "center" }}
          >
            SHOUTOUTS
          </h1>
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4">
            <TextBox
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <TextBox
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <Select
            name="type"
            options={["Programming", "Sales", "Technical"]}
            value={formData.type}
            onChange={handleChange}
          />

          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            className=" mb-4 px-3 py-[12px] block w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-theme-secondary sm:text-sm sm:leading-6 outline-none"
          />
          <button
            type="submit"
            className="bg-theme-accent text-white px-20 py-2 uppercase font-bold w-min rounded-md text-[16px]"
            style={{
              width: "100%",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Contact;
