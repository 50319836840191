import React from "react";

function Select({ title, options, name, onChange }) {
  return (
    <select
      className="select cursor-pointer bg-white mb-4 px-3 py-[12px] block w-full rounded-md border-0 shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-0 outline-none"
      name={name}
      onChange={onChange}
    >
      {options.map((op, idx) => (
        <option className="text-black" key={idx}>
          {op}
        </option>
      ))}
    </select>
  );
}

export default Select;
