import useAuth from "../hooks/useAuth";
import { auth } from "../utils/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "./icons/Logo";
import { Bars3Icon } from "@heroicons/react/24/solid";
import SideMenu from "./SideMenu";
import { useState } from "react";

export default function Navbar({ isHome }) {
  const { user: myUser } = useAuth();
  const nav = useNavigate();
  const loaction = useLocation();
  const [showSidemenu, setShowSidemenu] = useState(false);

  const handleSignout = async () => {
    await auth.signOut();
    nav("/login");
  };

  function toggleSidemenu() {
    setShowSidemenu((prev) => !prev);
  }

  const navButtns = [
    {
      text: "Sign In",
      clickHandle: () => nav("/login"),
      authenticated: false,
    },
    {
      text: "Sign Up",
      clickHandle: () => nav("/signup"),
      authenticated: false,
    },
    {
      text: "Sign out",
      clickHandle: handleSignout,
      authenticated: true,
    },
    {
      text: "Client Portal",
      clickHandle: () => nav("/download"),
      authenticated: true,
    },
  ];

  const navBtns = [];
  for (let index = 0; index < navButtns.length; index++) {
    const { text, clickHandle, authenticated } = navButtns[index];
    if (myUser && authenticated == false) continue;
    if (!myUser && authenticated == true) continue;
    if (loaction.pathname == "/download" && text == "Client Portal") continue;
    navBtns.push(
      <NavButton key={text} text={text} clickHandle={clickHandle} />
    );
  }

  return (
    <nav
      className={`bg-gradient-to-r ${
        isHome == true
          ? "from-[#210e47] to-[#15396c]"
          : "from-black to-gray-900"
      } flex justify-center items-center py-4 px-7 relative`}
    >
      <div className="w-full flex justify-between items-center max-w-6xl">
        <Logo />
        <button onClick={toggleSidemenu} className="w-10 md:hidden shrink-0">
          <Bars3Icon className=" text-white" />
        </button>
        <div className="hidden md:flex items-center gap-3">{navBtns}</div>
      </div>
      <SideMenu
        showSidemenu={showSidemenu}
        toggleSidemenu={toggleSidemenu}
        navBtns={navBtns}
      />
    </nav>
  );
}

function NavButton({ text, clickHandle }) {
  return (
    <button
      type="button"
      className={`relative text-center inline-flex items-center gap-x-1.5 bg-transparent border hover:border-gray-200 rounded-md px-3 py-2 text-sm font-medium text-white hover:text-gray-300 shadow-sm`}
      onClick={clickHandle}
    >
      {text}
    </button>
  );
}
