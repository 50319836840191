import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { setDoc, doc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDTBL58GTJEAjkuOVCW19peMDxpFjpXKak",
  authDomain: "aidancechart-stripe.firebaseapp.com",
  projectId: "aidancechart-stripe",
  storageBucket: "aidancechart-stripe.firebasestorage.app",
  messagingSenderId: "558664993015",
  appId: "1:558664993015:web:de1fceacf010bea7c52bf2",
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const auth = getAuth(app);
export const db = getFirestore(app);
