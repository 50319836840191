import React, { useState } from "react";
import Logo from "./icons/Logo";
import AudioPlayer from "./AudioPlayer";
import HeaderCarousel from "./HeaderCarousel";

function Header() {
  const [playAudio, setPlayAudio] = useState(false);
  const togglePlay = () => setPlayAudio(!playAudio);

  return (
    <header className="relative w-full h-[100vh] max-h-[900px] flex flex-col bg-gradient-to-r from-[#210e47] to-[#15396c]">
      <div className="header-bg"></div>
      <HeaderCarousel playAudio={playAudio} togglePlay={togglePlay} />
      <AudioPlayer
        playAudio={playAudio}
        togglePlay={togglePlay}
        setPlayAudio={setPlayAudio}
      />
    </header>
  );
}

export default Header;
