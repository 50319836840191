import { XMarkIcon } from "@heroicons/react/24/solid";

export default function SideMenu({ showSidemenu, toggleSidemenu, navBtns }) {
  return (
    <>
      <div
        onClick={toggleSidemenu}
        className={`${
          showSidemenu ? "fixed" : "hidden"
        } top-0 left-0 w-screen h-screen z-30`}
      ></div>
      <div
        className={`${
          showSidemenu ? "" : "translate-x-full"
        } flex flex-col fixed top-0 right-0 z-40 h-screen px-6 py-6 overflow-y-auto transition-transform bg-blue-950 w-[min(20rem,100%)]`}
      >
        <button
          type="button"
          onClick={toggleSidemenu}
          className="w-10 text-white ml-auto"
        >
          <XMarkIcon />
        </button>
        <ul className="flex flex-col gap-4 mt-5">{navBtns}</ul>
      </div>
    </>
  );
}
